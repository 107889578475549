
/* ------------- Header ------------- */
/* header {
  background-color: slateg;
} */

/* #root {
  background-color:azure;
} */

h1 {
  margin: 0px;
}

.header-container a {
  color: black;
  text-decoration: none;
  padding-bottom: 5px;
}

.header-container a:hover {
  color: grey;
}

.header-container {
  height: 10vh;
  width: 1024px;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid black;
}

.header-left, .header-right {
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 2vw; 

  padding-left: 2vw;
  padding-right: 2vw;
}

.header-left {
  gap: 20px;
}

.header-left, .header-right > * {
  cursor: pointer;
  font-size: 20px;
}

.header-image {
  height: 60%;
  border-radius: 50%;
}

#title {
  font-size: 30px;
}

.header__icon {
  height: 3vh;
  width: 3vh;
}

#contact-me {
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
}

/* ------------- mobile menu ------------- */
.mobile-menu {
  width: 100vw;
}

.mobile-menu a {
  color: black;
  text-decoration: none;
}

#back-button {
  width: 7vw;
  height: 7vw;
  text-align: center;
  cursor: pointer;
  margin-left: 2vw;
  margin-top: 2vw;
}

.mobile-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-menu-container > * {
  font-size: 40px;
  margin: 20px;
}


/* ------------- body ------------- */

.body {
  min-height: 90vh;
  width: 1024px;
  margin: auto;

}

/* ------------- homepage ------------- */

.homepage-container a {
  color: blue;
  text-decoration: none;
}

.homepage-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 5vw;
  padding-top: 50px;
  line-height: 1.5;
}

.education-image {
  width: 75px;
  height: 75px;
}

.education-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 20px;
}

.nostyle_link * {
  color: black;
  text-decoration: none;
}

/* .homepage-container > * {
  text-align: left;
  margin: 5px;
} */

.skills-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.skills-container ul {
  padding-inline-start: 15px;
}

.homepage-container section {
  /* border-left: 2px solid black; */
  padding-left: 10px;
  margin-bottom: 40px;
}

#propic-homepage {
  margin: auto;
  display: block;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}

/* .homepage-container h2 {
  text-align: center;
} */

/* ------------- resume ------------- */

.resume-container {
  text-align: center;
  justify-content: center;
  height: 100%;
  
}

.resume-container img {
  width: 100%;
  object-fit: cover; 
}

/* ------------- projects ------------- */

.projects-title {
  text-align: center;
  line-height: 0;
  padding-top: 10px;
}

.projects-container {

  /* background-color: antiquewhite; */

  width: 90%;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* ------------- project-thumbnail ------------- */

.project-thumbnail {
  
  width: 42%;
  margin: 4%;
  line-height: 1.3;
  
  cursor: pointer;

  box-sizing: border-box;
  border: 1px solid lightgray;
  border-radius: 5px;
  overflow: hidden;
}

.project-thumbnail:hover {
  background-color: whitesmoke;
}

.no-margin {
  margin: 0px;
}

.image-container {
  width: 100%;
  height: 150px;
  padding: 0px;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

#project-back-button {
  width: 40px;
  height: 40px;
}

.thumbnail-tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.thumbnail-tag {
  border: 1px solid lightgray;
  margin-right: 10px;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 10px;
}

/* ------------- Project ------------- */

.project-image {
  width: 80%;
  margin: auto;
  display: block;
  border-radius: 5px;
  display: block;
}

.project-container {
  line-height: 1.5;
  margin: 5vw;
  margin-top: 20px;
}

.padded-content {
  padding: 20px;
}

.project-header {
  margin-bottom: 30px;
}

.project-container > p, h3{
  font-size: 20px;
}

.project-container .date {
  font-size: 16px;
  margin: 0px;
  font-style: italic;
}

.project-container a {
  color: blue;
  text-decoration: none;
}

.project-container a:hover {
  color: lightblue;
}

/* ------------- footer ------------- */
.footer {
  height: 200px;
  width: 1024px;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  border-top: 1px solid black;
  padding-inline: 5vw;
  box-sizing: border-box;
}

.footer-left, .footer-right {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-content: center;

  gap: 20px;
  padding: 10px;
}

.footer-left-column p {
  margin: 5px;
  margin-inline: 0px;
}

.footer-right {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  gap: 10px;
  text-align: right;

  justify-content: center;
  align-items: flex-end;
}

.footer-right > a {
  text-decoration: none;
  color: black;
  padding-right: 10px;
}

.footer-right > a:hover {
  color: gray;
}

.footer-left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer h2 {
  margin: 0px;
}

#propic {
  border-radius: 50%;
  height: 100px;
  width: auto;
  object-fit: cover;
  margin-top: auto;
  margin-bottom: auto;
}

.social-icons-container {
  display: flex;
  flex-direction: row;

  margin-top: 10px;
  gap: 10px;
}

.socials-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* ------------- MOBILE ------------- */
@media only screen and (max-width: 1024px) {
  .header-container {
    width: 100%;
  }

  .body {
    width: 100%;
  }

  .footer {
    width: 100%;
  }

  .footer-left, .footer-right {
    width: 44%;
  }

  .projects-container {
    padding-top: 0%;
    width: 100%;
  }

  .project-thumbnail {
    width: 90%;
    margin: 10%;
    margin-bottom: 5%;
  }

  * {
    font-size: 95%;
  }  

  #propic-homepage {
    width: 150px;
    height: 150px;
  }

  .education-image {
    width: 50px;
    height: 50px;
  }

  #propic {
    border-radius: 50%;
    height: 75px;
    width: auto;
    object-fit: cover;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socials-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}